import {ChartModifierBase2D} from "scichart/Charting/ChartModifiers/ChartModifierBase2D";
import {DpiHelper} from "scichart/Charting/Visuals/TextureManager/DpiHelper";
import _ from "lodash";

/**
 * CustomCardChartLegendModifier is a custom ChartModifierBase2D which listens to mouse move events and performs hit-testing on the chart and 
 * then calls the rollOverCallback with given x value (time) and context Y values. Context Y values are the y values of the contextId (truck, sensor, etc).
 * This is used for card chart to show the context y values on the legend when user hovers over the chart.
 */
class CustomCardChartLegendModifier extends ChartModifierBase2D {
    HIT_TEST_RADIUS = 1 * DpiHelper.PIXEL_RATIO;
    #rollOverCallback = null;
    constructor(rollOverCallback) {
        super();
        this.#rollOverCallback = rollOverCallback;
    }

    /**
     * Override to handle mouse move events
     */
    modifierMouseMove(args) {
        super.modifierMouseMove(args);

        const { x, y } = args.mousePoint;  // Pixel coordinates
        const premultipliedX = x * DpiHelper.PIXEL_RATIO;
        const premultipliedY = y * DpiHelper.PIXEL_RATIO;
        const renderableSeriesArray = this.parentSurface.renderableSeries.asArray();

        // Perform a hit test on each series and collect Y-values if there's a hit
        const yValuesMap = new Map();
        let xValue;
        renderableSeriesArray
            .map(rs => {
                const hitTestInfo = rs.hitTestProvider.hitTest(
                    premultipliedX,
                    premultipliedY,
                    this.HIT_TEST_RADIUS
                );
                //Set the xValue to the xValue of the first hit test only once
                if(_.isNil(xValue)){
                    xValue = hitTestInfo.xValue;
                }
                //extract contextId from dataSeriesName
                //contextId can be a truck, sensor, or any other asset
                const contextId = rs.dataSeries.dataSeriesName.split(' ')[0];

                //store contextId and yValues for the given into yValuesMap
                if(_.isNil(yValuesMap.get(rs.xAxisId))){
                  yValuesMap.set(rs.xAxisId, new Array());
                }               

                yValuesMap.get(rs.xAxisId).push({
                    contextId: contextId,
                    yValue: hitTestInfo.yValue
                });
            });
        //Call the rollOverCallback with xValue and yValuesMap
        if(!_.isNil(this.#rollOverCallback)) {
            this.#rollOverCallback(xValue, yValuesMap);
        }
    }
}
export default CustomCardChartLegendModifier;