import _ from 'lodash';
import { filterState } from '../../common/filtering/filterSelectors';

const dataExplorationDisplayState = state => {

  let filterDetails = filterState(state);

  return {

    ...filterDetails,

    chartAxis: _.isNil(state) ? [] : state.chartAxis,

    // The full list of trucks returned from the service.
    trucks: _.isNil(state) ? [] : state.trucks,

    // The list of trucks fit for the filter conditions
    contexts: _.isNil(state) ? [] : state.contexts,

    // The selected truck (the context for the cards)
    selectedContext: _.isNil(state) ? null : state.selectedContext,

    // The collection of data ranges for the selected truck and time
    dataRanges: _.isNil(state) ? null : state.dataRanges,

    dataAgeActual: _.isNil(state) ? '' : state.dataAgeActual,
    dataAgeDisplay: _.isNil(state) ? '-' : state.dataAgeDisplay,

    customDurations: _.isNil(state) ? [
      { value: 60, label: '1 hr' },
      { value: 120, label: '2 hrs' },
      { value: 240, label: '4 hrs' },
      { value: 480, label: '8 hrs' },
      { value: 720, label: '12 hrs' },
      { value: 1440, label: '24 hrs' },
    ] : state.customDurations,

    selectedTimeFrame: _.isNil(state) ? { value: null, label: 'Custom' } : state.selectedTimeFrame,
    selectedCustomStartTime: _.isNil(state) ? null : state.selectedCustomStartTime,
    selectedChartStartTime: _.isNil(state) ? null : state.selectedChartStartTime,
    selectedCustomDuration: _.isNil(state) ? { value: 720, label: '12 hrs' } : state.selectedCustomDuration,
    
    selectedCustomStartTimeDisplay: _.isNil(state) ? null : state.selectedCustomStartTimeDisplay,

    queryRunning: _.isNil(state) ? false : state.queryRunning,

    // Boolean to indicate the users selections exceed the maximum sensor minutes
    exceededMaximumDuration : _.isNil(state) ? false : state.exceededMaximumDuration,

    // Label to display to the user when their selections exceed the maximum sensor minutes
    maximumDurationLabel: _.isNil(state) ? null : state.maximumDurationLabel,

    truckQueryRunning: _.isNil(state) ? false : state.truckQueryRunning,
    truckDetailsQueryRunning: _.isNil(state) ? false : state.truckDetailsQueryRunning,
  };

};

export {
  dataExplorationDisplayState
};