import _ from "lodash";
import React, {Component} from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withProps } from "recompose";

import DataExplorer from '../../controls/dataExplorer/dataExplorer';
import { Typography, IconButton, Chip, Tooltip, Box, Paper } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FilterDialog from '../../common/filtering/filterDialog';
import moment from "moment";

import Progress from "../../controls/progress";
import { AutoCompleteMDT, DateTimePickerMDT } from "../../controls/mdtMuiControls";
import * as dataExplorationDisplayActions from '../../../state/displays/dataExplorationDisplay/dataExplorationDisplayActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import ComponentTypes from '../../componentTypes';
import { dataExplorationDisplayState } from '../../../state/displays/dataExplorationDisplay/dataExplorationDisplaySelector';
import * as timeService from '../../../state/displays/dataExplorationDisplay/services/timeService';
import DataExplorationView from "../../common/dataExploration/view/dataExploration";
import getDisplayStyles from '../../common/styles/displayStyles';
import { appState as applicationState } from '../../../state/app/appSelectors';

const displayStyles = getDisplayStyles();

const styles = {
  ...displayStyles,
  pageHeader: {
    ...displayStyles.pageHeader,
    paddingLeft: 0,
  },
  contextSelection: {
    width: '180px',
    marginLeft: 1,
    paddingRight: 1,
  },
  timeFilterContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    height: '56px',
    marginRight: 6
  },
  truckNextPrevActions: {
    marginRight: 6
  }
};

class DataExplorationDisplay extends Component {

  constructor(props) {
    super(props);
    this.manualRefreshTime = null;
  }

  periodicRefreshDisplay() {
    // Periodically refresh the display. NOTE: Loading the display data
    // implicitly handles the setting of the selected context which will
    // trigger the loading of the data age if necessary (see componentDidUpdate)
    return setInterval(()=>{
      this.loadDisplayWithStartAndEndTimes();
    }, 180000); // 3 minutes
  }

  periodicRefreshRelativeTime() {
    // Periodically refresh the label for the data age
    return setInterval(()=>{
      this.props.refreshRelativeTime();
    }, 60000); // 1 minute
  }

  componentDidMount() {
    this.manualRefreshTime = moment().unix();
    this.loadDisplayWithStartAndEndTimes();

    // Start the timer for refreshing the display.
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();
    // Start the timer for refreshing the relative time label
    this.periodicRelativeTimeRefreshId = this.periodicRefreshRelativeTime();

    if (_.isNil(this.props.selectedCustomStartTime) && !_.isNil(this.props.selectedCustomDuration)) {
      this.props.setCustomStartTime(moment().subtract(this.props.selectedCustomDuration.value, 'minutes'));
    }
    if (_.isNil(this.props.selectedChartStartTime) && !_.isNil(this.props.selectedCustomDuration)) {
      this.props.setChartStartTime(moment().subtract(this.props.selectedCustomDuration.value, 'minutes'));
    }
  }

  componentDidUpdate(prevProps) {
    // Load the page if the selected context changes
    if (!_.isEqual(prevProps.selectedContext, this.props.selectedContext)) {
      this.loadDataAge();
      if (!_.isNil(prevProps.selectedContext)) {
        const startTime = timeService.calculateStartTimeUsingTimeFrame(this.props.selectedCustomDuration);
        this.props.loadTruckDetails(startTime);
      }
    }
  }

  manualRefreshDisplay() {
    // Refresh the display data when a user manual triggers a refresh.
    // NOTE: Loading the display data implicitly handles the setting of
    // the selected context which will trigger the loading of the data
    // age if necessary (see componentDidUpdate)
    this.loadDisplayWithStartAndEndTimes();
    this.manualRefreshTime = moment().unix();
  }

  loadDataAge() {
    if (!_.isNil(this.props.selectedContext) && !_.isNil(this.props.selectedContext.truckId)) {
      // Update the Data Age
      this.props.queryDataAge(this.props.selectedContext.truckId);
    } else {
      // When the selected owner has no trucks, we want to clear the existing data age
      this.props.clearDataAge();
    }
  }

  loadDisplayWithStartAndEndTimes() {
    // First calculate the start time and end time
    // We have to do this because when the display loads, this componentDidMount gets called in the component lifecycle
    // Normally we would be able to look at the state when we dispatch the loadDisplay action
    // But since we haven't even loaded the display yet, the state for it does not exist
    // There we have to manually setup the start and end time parameters that are used in the Data Exploration Display query
    let startTime;
    if (this.props.selectedTimeFrame.label === 'Custom') {
      startTime = timeService.calculateStartTimeUsingTimeFrame(this.props.selectedCustomDuration);
    } else {
      startTime = timeService.calculateStartTimeUsingTimeFrame(this.props.selectedTimeFrame);
    }

    // Load the display data. This should include getting all trucks
    this.props.loadDisplay(startTime);
  }

  render() {
    // Check for an empty filter collection here to keep the markup cleaner. This disables the add filter button.
    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));
    let showAdditionalTimeFilters = !_.isNil(this.props.selectedTimeFrame) && this.props.selectedTimeFrame.label === 'Custom';

    return (
      <Box id="screenshot-area" sx={styles.page}>
        <Box sx={styles.pageContent}>
          <Box sx={styles.pageHeader}>
            <Box sx={styles.pageHeaderTitle}>
              <Typography variant={'h6'}>Data Exploration</Typography>
            </Box>
            <Box sx={styles.pageHeaderActions}>
              <Box sx={styles.pageHeaderButton}>
                <Tooltip title='Refresh'>
                  <IconButton onClick={() => this.manualRefreshDisplay()} size='large'>
                    <RefreshIcon/>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Box sx={styles.filterContainer}>
              <Typography variant={'subtitle1'}>FILTER:</Typography>
              {
                this.props.appliedFilters.map((filterContext, index) => {
                  return (
                    <Chip
                      key={index}
                      sx={styles.childComponent}
                      label={filterContext.chipLabel}
                      onDelete={() => this.props.deleteFilterContext(index)}
                    />
                  )
                })
              }
              <IconButton
                onClick={() => this.props.openFilterDialog()}
                disabled={emptyFilters}
                sx={styles.childComponent}
                size='large'><AddCircleIcon/></IconButton>
            </Box>
            <Box sx={styles.dataAgeContainer}>
              <Typography sx={styles.childComponent}>Data as of:</Typography>
              <Typography sx={styles.childComponent}>{this.props.dataAgeDisplay}</Typography>
            </Box>
          </Box>
          {/* Truck Selection */}
          <Box sx={{
                display:'flex',
                alignItems: 'center',
                paddingBottom: 2
              }}>
            <Typography variant={'subtitle1'}>TRUCK:</Typography>
            <AutoCompleteMDT
              sx={{width: '150px', marginLeft: 1}}
              options={this.props.contexts}
              value={this.props.selectedContext}
              onChange={(event, value, reason) => {this.props.selectContext(value)}}
              noOptionsText={'No trucks found...'}
              />
            <Box sx={styles.truckNextPrevActions}>
              <Tooltip title=
                      {
                      (!_.isEmpty(this.props.contexts) &&
                      _.first(this.props.contexts) === this.props.selectedContext) ?
                      "No Prev Truck" :
                      "Prev Truck"
                      }>
                <span>
                  <IconButton
                    disabled={
                      !_.isEmpty(this.props.contexts) &&
                      _.first(this.props.contexts) === this.props.selectedContext
                    }
                    onClick={() => this.props.onSelectPrevTruck()}
                    size="large"
                  >
                    <NavigateBeforeIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={
                      (!_.isEmpty(this.props.contexts) &&
                      _.last(this.props.contexts) === this.props.selectedContext) ?
                      "No Next Truck" :
                      "Next Truck"
                      }>
                <span>
                  <IconButton
                    disabled={
                      !_.isEmpty(this.props.contexts) &&
                      _.last(this.props.contexts) === this.props.selectedContext
                    }
                    onClick={() => this.props.onSelectNextTruck()}
                    size="large"
                  >
                    <NavigateNextIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{height: "max-content", width: "fit-content", backgroundColor: "#212121"}}>
            {/* Form Container */}
            <Paper sx={{
                    marginBottom: 2,
                    width: 'fit-content'
                }}
            >
                <Box sx={styles.viewContainer}>
                  {/* Data Explorer*/}
                  <DataExplorer
                    isBusy={this.props.truckDetailsQueryRunning}
                    dataRanges={this.props.dataRanges}
                    durations={this.props.customDurations}
                    selectedDateTime={this.props.selectedCustomStartTime}
                    selectedDuration={this.props.selectedCustomDuration}
                    onDateTimeChange={(dateTime) => this.props.reloadDataForTruck(this.props.selectedContext, dateTime)} //reload data only triggered if new selectedDateTime out of current query range
                    onDurationChange={(duration) => this.props.setCustomDuration(duration)}
                    onFindNext={() => this.props.findNextWithDataReload()} // data reload only trigger when no more data at left of current dataRange
                    onFindPrevious={() => this.props.findPreviousWithDataReload()} // data reload only trigger when no more data at right of current dataRange
                    reachedLeftEnd={this.props.reachedLeftEnd}
                    selectedCustomStartTimeDisplay={this.props.selectedCustomStartTimeDisplay}
                    setCustomStartTime={(value) => this.props.setCustomStartTime(value)}
                    setChartStartTime={(value) => this.props.setChartStartTime(value)}
                    setCustomStartTimeDisplay={(value) => this.props.setCustomStartTimeDisplay(value)}
                    useAccordion
                    zoomStats={this.props.chartAxis.primary}
                    customSpecs={{
                      CHART_WIDTH: 1503,
                      CHART_HEIGHT: 80,
                      DATA_LINE_HEIGHT: 20,
                    }}
                  />
                </Box>
            </Paper>
            <Box sx={styles.viewContainer}>
              <DataExplorationView
                asset={(_.isNil(this.props.selectedContext) ? {} : this.props.selectedContext)}
                selectedTimeFrame={this.props.selectedTimeFrame}
                selectedCustomStartTime={this.props.selectedChartStartTime}
                selectedCustomDuration={this.props.selectedCustomDuration}
                manualRefreshTime={this.manualRefreshTime}
                owner={ComponentTypes.DATA_EXPLORATION_DISPLAY}
                user={this.props.user}
                chartModifiers={{
                  MouseWheelZoomModifier: 'y',
                  ZoomPanModifier: 'y',
                }}
                onAxisUpdateCallback={(xAxisId, min, max) =>
                  this.props.updateChartAxis(this.props.stateDef, xAxisId, min, max)
                }
              />
            </Box>
          </Box>
          <FilterDialog stateDef={this.props.stateDef}/>
          <Progress open={this.props.queryRunning}/>
        </Box>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.DATA_EXPLORATION_DISPLAY : props.stateKey,
      type: ComponentTypes.DATA_EXPLORATION_DISPLAY,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let componentState = dataExplorationDisplayState(state[stateDef.key]);
  let appState = applicationState(state);
  return {
    contexts: componentState.contexts,
    chartAxis: componentState.chartAxis,
    selectedContext: componentState.selectedContext,
    dataAgeDisplay: componentState.dataAgeDisplay,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    timeFrames: componentState.timeFrames,
    customDurations: componentState.customDurations,
    selectedTimeFrame: componentState.selectedTimeFrame,
    selectedCustomStartTime: componentState.selectedCustomStartTime,
    selectedCustomDuration: componentState.selectedCustomDuration,
    selectedChartStartTime: componentState.selectedChartStartTime,
    user: appState.user,
    selectedCustomStartTimeDisplay: componentState.selectedCustomStartTimeDisplay,
    queryRunning: componentState.queryRunning,
    durations: componentState.durations,
    truckDetailsQueryRunning: componentState.truckDetailsQueryRunning,
    dataRanges: componentState.dataRanges,
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: (startTime, endTime) => { dispatch(dataExplorationDisplayActions.loadDisplay(props.stateDef, startTime, endTime))},
    queryDataAge: (truckId) => { dispatch(dataExplorationDisplayActions.queryDataAge(props.stateDef, truckId))},
    clearDataAge: () => { dispatch(dataExplorationDisplayActions.clearDataAge(props.stateDef))},
    refreshRelativeTime: () => { dispatch(dataExplorationDisplayActions.refreshRelativeTime(props.stateDef))},
    selectContext: (context) => {dispatch(dataExplorationDisplayActions.selectContext(props.stateDef, context))},
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilterContext: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    setCustomStartTime: (startTime) => {dispatch(dataExplorationDisplayActions.setCustomStartTime(props.stateDef, startTime))},
    setCustomDuration: (duration) => {dispatch(dataExplorationDisplayActions.setCustomDuration(props.stateDef, duration))},
    selectNextTruck: () => { dispatch(dataExplorationDisplayActions.selectNextTruck(props.stateDef))},
    selectPrevTruck: () => { dispatch(dataExplorationDisplayActions.selectPrevTruck(props.stateDef))},
    setCustomStartTimeDisplay: (startTime) => {dispatch(dataExplorationDisplayActions.setCustomStartTimeDisplay(props.stateDef, startTime))},
    selectDuration: (duration) => dispatch(dataExplorationDisplayActions.selectDuration(props.stateDef, duration)),
    onSelectNextTruck: () => dispatch(dataExplorationDisplayActions.selectNextTruck(props.stateDef)),
    onSelectPrevTruck: () => dispatch(dataExplorationDisplayActions.selectPrevTruck(props.stateDef)),
    reloadDataForTruck: (truckId, selectedDateTime)  => dispatch(dataExplorationDisplayActions.reloadDataForTruck(props.stateDef, truckId, selectedDateTime)),
    findNextWithDataReload: () => dispatch(dataExplorationDisplayActions.findNextWithDataReload(props.stateDef)),
    findPreviousWithDataReload: () => dispatch(dataExplorationDisplayActions.findPreviousWithDataReload(props.stateDef)),
    setChartStartTime: (chartStartTime) => dispatch(dataExplorationDisplayActions.setChartStartTime(props.stateDef, chartStartTime)),
    updateChartAxis: (stateDef, xAxisId, min, max) => {
      dispatch(dataExplorationDisplayActions.updateChartAxis(stateDef, xAxisId, min, max)); // NEW DISPATCH ACTION
    },
    loadTruckDetails: (startTime) => dispatch(dataExplorationDisplayActions.loadTruckDetails(props.stateDef, startTime)),
  }
};

export default compose (
  withProps(stateDefinition)
)(connect(mapStateToProps, mapDispatchToProps)(DataExplorationDisplay))
