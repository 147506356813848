import React, {Component} from 'react';
import _ from "lodash";
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {withProps} from "recompose";

import { Typography, IconButton, Chip, Box } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterDialog from '../../common/filtering/filterDialog';
import Progress from "../../controls/progress";

import ComponentTypes from '../../componentTypes';
import * as fleetOverviewActions from '../../../state/displays/fleetOverview/fleetOverviewActions';
import * as filterActions from '../../../state/common/filtering/filterActions';
import fleetOverviewViews from '../../../state/displays/fleetOverview/fleetOverviewViews';
import { fleetOverviewState } from '../../../state/displays/fleetOverview/fleetOverviewSelectors';
import { displayConfigurationState, appState as applicationState } from '../../../state/app/appSelectors';
import { trackPage } from '../../../helpers/googleAnalyticsHelper';
import FracOverviewCard from "../../cards/fracOverviewCard/fracOverviewCard";
import FracJobOverviewCard from "../../cards/fracJobOverviewCard/fracJobOverviewCard";
import OWNERS from "../../common/owners";

const styles = {
  flex: {
    flex: 1
  },
  page: {
    display:'flex',
    flexFlow: 'column',
    backgroundColor: 'background.default',
    paddingTop: '64px',
  },
  pageContent: {
    // Set the size of the page, this needs to match the size of the views
    width: '1732px',
    // Prevents the left size of the display from becoming unreachable when
    // horizontal scrolling is required
    margin: 'auto',
  },
  pageHeader: {
    display: 'flex',
    flexFlow: 'wrap',
    alignItems: 'center',
    paddingLeft: '10px', //Plus the internal spacing of the layout grid (10px)
    paddingRight: '10px', //Plus the internal spacing of the layout grid (10px)
    height: 'auto',
  },
  pageHeaderTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '170px',
  },
  pageHeaderActions: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },
  pageHeaderButton: {
    paddingLeft: 1.5,
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  filterContainer: {
    display:'flex',
    flexFlow:'row nowrap',
    alignItems: 'center',
    paddingLeft: 3,
  },
  filterItems: {
    paddingLeft: 3,
  },
  childComponent : {
    margin: 1,
  },
  cardContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    paddingLeft: '10px',
  },
  pagingContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  pagingLabel: {
    paddingRight: 2,
  },
};

//Define the list of owner ids that should display the FracOverviewCard instead of the FracJobOverviewCard
const ownerIdInclusionList = [OWNERS.NEXTIER, OWNERS.ALPINE_ENERGY];

class FleetOverview extends Component {

  componentDidMount() {
    // Track GA View
    trackPage(ComponentTypes.FLEET_OVERVIEW, this.props.user);

    this.props.loadDisplay(this.props.user.userId);
    this.periodicDisplayRefreshId = this.periodicRefreshDisplay();

  }

  componentWillUnmount() {
    if (!_.isNil(this.periodicDisplayRefreshId)) {
      clearInterval(this.periodicDisplayRefreshId);
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pagedFleets !== this.props.pagedFleets) {
      this.props.cleanUpState(prevProps.pagedFleets, this.props.pagedFleets);
    }
  }

  periodicRefreshDisplay() {
    return setInterval(()=>{
      this.manualRefreshDisplay();
    }, 180000); // 3 minutes
  }

  manualRefreshDisplay() {
    this.props.loadDisplay(this.props.user.userId);
  }

  isRenderFracOverviewCard() {
    return (!_.isNil(this.props.selectedOwner) && ownerIdInclusionList.includes(this.props.selectedOwner.value)) || (!_.isNil(this.props.user) && ownerIdInclusionList.includes(this.props.user.ownerId));
  }

  render() {

    let emptyFilters = (_.isNil(this.props.filters) || _.isEmpty(this.props.filters));

    let pageCount = _.isEmpty(this.props.filteredFleets) ? 0 : _.chunk(this.props.filteredFleets, 12).length;

    // We only support one view in the fleet overview at the moment, but the configuration is structured to
    // support the multiple views that are coming down the pipe. So for now just grab the dashboard configuration
    // to see which type of card to display.

    let viewConfiguration = _.isNil(this.props.configuration) || _.isNil(this.props.configuration.views) ?
      {} : _.find(this.props.configuration.views, ['id', fleetOverviewViews.DASHBOARD]);

    let displayConfiguration = viewConfiguration.configuration;

    return (
      <Box sx={styles.page}>
        <Box sx={styles.pageContent}>
          <Box sx={styles.pageHeader}>
            <Box sx={styles.pageHeaderTitle}>
              <Typography variant={'h6'}>Fleet Overview</Typography>
            </Box>
            <Box sx={styles.pageHeaderActions}>
              <Box sx={styles.pageHeaderButton}>
                <IconButton onClick={() => this.manualRefreshDisplay()} size="large">
                  <RefreshIcon/>
                </IconButton>
              </Box>
            </Box>
            <Box sx={styles.filterContainer}>
              <Typography variant={'subtitle1'}>FILTER:</Typography>
              <Box sx={styles.filterItems}>
              {
                this.props.appliedFilters.map((filterContext, index) => {
                  return (
                    <Chip
                      key={index}
                      sx={styles.childComponent}
                      label={filterContext.chipLabel}
                      onDelete={() => this.props.deleteFilter(index)}
                    />
                  )
                })
              }
              </Box>
              <IconButton
                onClick={() => this.props.openFilterDialog()}
                disabled={emptyFilters}
                sx={styles.childComponent}
                size="large"><AddCircleIcon/></IconButton>
            </Box>
            <Typography sx={styles.flex}/>
            {
              pageCount > 1 &&
              <Box sx={styles.pagingContainer}>
                <Typography sx={styles.pagingLabel}>{'Page ' + (this.props.currentPage + 1) + ' of ' + (pageCount)}</Typography>
                <IconButton
                  disabled={this.props.currentPage - 1 < 0}
                  onClick={() => { this.props.setCurrentPage(this.props.currentPage-1)}}
                  size="large"><ChevronLeftIcon/></IconButton>
                <IconButton
                  disabled={this.props.currentPage + 1 >= pageCount}
                  onClick={() => { this.props.setCurrentPage(this.props.currentPage+1)}}
                  size="large"><ChevronRightIcon/></IconButton>
              </Box>
            }
          </Box>
          <Box sx={styles.cardContainer}>
            {
              this.props.pagedFleets.map((fleet, index) => {
                let cardStateKey = this.props.stateDef.key + '-' + fleet.id;
                return this.isRenderFracOverviewCard() ?
                    (<FracOverviewCard key={index} stateKey={cardStateKey} selectedContext={fleet}/>):
                    (<FracJobOverviewCard key={index} stateKey={cardStateKey} selectedContext={fleet}/>)
              })
            }
          </Box>
          <FilterDialog
              stateDef={this.props.stateDef}
              appliedFilterLabels={this.props.appliedFilters.map(f => f.label)}
          />
          <Progress open={this.props.queryRunning}/>
        </Box>
      </Box>
    );
  }
}

const stateDefinition = (props) => {
  return {
    stateDef: {
      key: _.isNil(props.stateKey) ? ComponentTypes.FLEET_OVERVIEW : props.stateKey,
      type: ComponentTypes.FLEET_OVERVIEW,
    }
  }
};

const mapStateToProps = (state, props) => {
  const { stateDef } = props;
  let appState = applicationState(state);
  let componentState = fleetOverviewState(state[stateDef.key]);
  return {
    configuration: displayConfigurationState(state, ComponentTypes.FLEET_OVERVIEW),
    queryRunning: componentState.queryRunning,
    fleets: componentState.fleets,
    filteredFleets: componentState.filteredFleets,
    pagedFleets: componentState.pagedFleets,
    appliedFilters: componentState.appliedFilters,
    filters: componentState.filters,
    currentPage: componentState.currentPage,
    user: appState.user,
    selectedOwner: appState.selectedOwner
  }
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadDisplay: (userId) => { dispatch(fleetOverviewActions.loadDisplay(props.stateDef, userId)) },
    openFilterDialog: () => { dispatch(filterActions.openFilterDialog(props.stateDef))},
    deleteFilter: (index) => { dispatch(filterActions.deleteFilter(props.stateDef, index))},
    cleanUpState: (oldFleets, newFleets) => { dispatch(fleetOverviewActions.cleanUpState(props.stateDef, oldFleets, newFleets)) },
    setCurrentPage: (currentPage) => { dispatch(fleetOverviewActions.setCurrentPage(props.stateDef, currentPage)) }
  }
};

export default compose(
  withProps(stateDefinition)
)(connect(mapStateToProps,mapDispatchToProps)(FleetOverview))